<template>
    <v-dialog v-model="dialog" persistent transition="scale-transition" max-width="1200px">
        <v-card class="elevation-1">

            <ValidationObserver ref="refFormUserEdit" v-slot="{ invalid, validated}" :immediate="true">
                <v-form name="form" @submit.prevent="updateUsuario">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click.native="handleModal">
                            <v-icon>fas fa-times-circle</v-icon>
                        </v-btn>

                        <v-toolbar-title>Editar datos del Usuario</v-toolbar-title>
                        <v-spacer></v-spacer>

                        <v-toolbar-items>
                            <v-btn class="danger" text type="submit" :disabled="invalid || !validated">
                            <v-icon class="ma-2" dense large>fas fa-save</v-icon>Guardar cambios</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>

                    <v-card-text>
                        <v-container fluid>

                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" :immediate="true">
                                        <v-autocomplete 
                                            :items="roles"
                                            item-text="role"
                                            item-value="role" 
                                            v-model="dataEditUsuario.roleName"
                                            label="Rol del usuario*"
                                            clearable prepend-icon="fas fa-user-tag"
                                            :error-messages="errors"
                                            :success="valid">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <v-autocomplete 
                                        :items="empleados"
                                        item-text="nombreCompleto" 
                                        item-value="id" 
                                        v-model="dataEditUsuario.empleadoId" 
                                        label="Empleado"
                                        prepend-icon="fas fa-user">
                                    </v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" :immediate="true">
                                        <v-text-field 
                                            label="usuario*"
                                            prepend-icon="fas fa-user"
                                            v-model="dataEditUsuario.username"
                                            :error-messages="errors"
                                            :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required|email" v-slot="{ errors }" :immediate="true">
                                        <v-text-field 
                                            label="Correo Electronico*"
                                            prepend-icon="fas fa-envelope"
                                            type="email"
                                            v-model="dataEditUsuario.email"
                                            :error-messages="errors">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <Password :password.sync="dataEditUsuario.password" name="password"
                                    :label="hasPassword ? 'Contraseña*' : 'Contraseña'"
                                    hint="Modificar acceso al sistema.." vid="password" rules="min:8|max:60"/>
                                </v-col>

                               <v-col cols="12" sm="6" md="4">
                                    <Password :password.sync="dataEditUsuario.passwordcp" name="passwordcp"
                                    :label="hasPassword ? 'Confirmar contraseña*' : 'Confirmar contraseña'" hint="Confirmar contraseña"
                                    vid="passwordcp" :rules="hasPassword ? 'required|min:8|max:60|confirmed:password' : 'min:8|max:60'"/>
                               </v-col>
                            </v-row>
                            <small>* Indica son campos requeridos</small>

                        </v-container>
                    </v-card-text>
                </v-form>
            </ValidationObserver>

        </v-card>
    </v-dialog>
</template>

<script>
import Password from '@/components/shared/Password.vue';
import UsuarioDataService from '@/service/usuario/usuarioDataService';

export default {
    name: "EditUsuario",
    props: {
        dialog: { default: false },
        handleModal: { type: Function },
        dataEditUsuario: {},
        initialize: { type: Function },
        roles: { type: Array }
    },
    components: { Password },
    created() {
        this.getEmpleados();
    },
    data() {
        return {
            submitted: false,
            empleados: [],
        }
    },
    methods: {
        updateUsuario() {
            this.$refs.refFormUserEdit.validate().then( isValid => {
                if( isValid ) {
                    UsuarioDataService.update( this.dataEditUsuario ).then( _ => {
                        if(this.hasPassword && this.sameUser) {
                            this.$swal.fire({ position: 'top-end', title: 'Favor de iniciar sesión', text: 'Se ha actualizado el usuario y la contraseña', icon: 'success', showConfirmButton: false, timer: 1500 });
                            setTimeout(() => {
                                this.$store.dispatch('auth/logout');
                                this.$router.push('/');
                            }, 1500);
                        } else {
                            this.initialize();
                            this.handleModal();
                            this.$refs.refFormUserEdit.reset();
                            this.$swal.fire({ position: 'top-end', title: 'Usuario actualizado', text: 'El usuario se ha actualizado correctamente', icon: 'success', showConfirmButton: false, timer: 1500 });                            
                        }
                    }).catch( err => {
                        this.$swal.fire({ position: 'top-end', title: 'Error', html: err.response, icon: 'error', showConfirmButton: false, timer: 1500 });
                    });
                }
            }).catch( err => {
                this.$swal.fire({ position: 'top-end', title: 'Error', text: err.message, icon: 'error', showConfirmButton: false, timer: 1500 });
            });
        },
        async getEmpleados() {
            const employeeId = this.dataEditUsuario.empleado ? this.dataEditUsuario.empleado.id : null;
            this.empleados = await UsuarioDataService.getAllEmpleado(employeeId).then( resp => resp.data ) || [];
        }
    },
    computed: {
        hasPasswordMatch() {
            return this.dataEditUsuario.password === this.dataEditUsuario.passwordcp
        },
        hasPassword(){
            return this.dataEditUsuario.password != undefined && this.dataEditUsuario.password != ""
        },
        sameUser() {
            return this.dataEditUsuario.id == UsuarioDataService.getCurrentUser().id
        }

    }
}
</script>
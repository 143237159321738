<template>
    <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12">
            <v-card class="elevation-4">

                <v-card-title>
                    <v-toolbar flat>
                        <v-btn small dark color="primary" @click="dialog = true">
                            <v-icon left>fas fa-plus-square</v-icon>Nuevo usuario
                        </v-btn>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-col cols="12" md="8" sm="4" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search" label="Buscar usuario" single-inline hide-details @keydown.enter="searchUser"></v-text-field>
                        <v-btn icon class="mt-4 ml-2">
                            <v-icon dense>fas fa-sync</v-icon>
                        </v-btn>
                        <Columns :headers.sync="headers"></Columns>
                    </v-col>
                </v-card-title>

                <v-data-table dense :headers="filteredHeaders" :items="usuarios" class="elevation-1"
                :loading="loading" :options.sync="options"
                :footer-props="{ 'items-per-page-options': [ 25, 50, 100 ] }"
                :server-items-length="totalUsers">
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="editarUsuario(item)" v-if="item.activo">fas fa-edit</v-icon>
                        <v-icon small class="mr-2" @click="lockUser(item)" v-if="item.activo">fas fa-trash-alt</v-icon>
                    </template>

                    <template v-slot:[`item.eliminado`]="{ item }">
                        <div class="center">
                            <v-chip :color="colorstatus(item.activo)" x-small dark v-text="item.activo ? 'Activo': 'Inactivo'"></v-chip>
                        </div>
                    </template>
                </v-data-table>

            </v-card>
        </v-col>

        <ModalNuevoUsuario :dialog.sync="dialog" :initialize.sync="initTableUser" :roles="roles" v-if="dialog" :handleModal="handleModal"></ModalNuevoUsuario>
        <ModalEditarUsuario :dialog.sync="dialogEdit" :dataEditUsuario.sync="dataEditUsuario" :initialize.sync="initTableUser" :roles="roles" v-if="dialogEdit" :handleModal="handleEditModal"></ModalEditarUsuario>
    </v-row>
</template>

<script>
import UsuarioDataService from '@/service/usuario/usuarioDataService'
import ModalNuevoUsuario from './NuevoUsuario.vue';
import ModalEditarUsuario from './EditarUsuario.vue';
import { getParamsPagination } from '@/utils/pagination';
import Columns from '@/components/shared/Columns.vue';

export default {
    name: 'Usuario',
    components: { ModalNuevoUsuario, ModalEditarUsuario, Columns },
    created() {
        this.initTableUser();
        this.getRoles();
    },
    data() {
        return {
            roles           : [],
            usuarios        : [],

            options         : {},
            totalUsers      : 0,
            loading         : false,
            search          : '',
                
            dialog          : false,
            dialogEdit      : false,
            dataEditUsuario : [],
            jsonUsuario:    [],

            headers: [
                { text: 'Acciones', value: 'actions', sortable: false, groupable: false, show: true },
                { text: 'ID', sortable: false, value: 'id', align: ' d-none', visible: false, groupable: false, show: false },
                { text: 'Usuario', value: 'username', sortable: false, groupable: false, show: true },
                { text: 'Empleado', value: 'empleado.nombreCompleto', sortable: false, groupable: false, show: true },
                { text: 'Correo', value: 'email', sortable: false, groupable: false, show: true },
                { text: 'Rol', value: 'roleName', sortable: false, groupable: false, show: true },
                { text: 'Estatus (g)', value: 'eliminado', sortable: false, groupable: false, show: true },
            ]
        }
    },
    methods: {
        searchUser() {
            this.options.page = 1;
            this.initTableUser(this.options);
        },
        initTableUser( options = {} ) {
            this.loading = true;
            const params = getParamsPagination({ options, search: this.search });

            UsuarioDataService.getAll(params).then( response => {
                const { rows, totalItem } = response.data;
                this.usuarios = rows;
                this.totalUsers = totalItem;
            }).catch( err => {
                this.$swal.fire({ position: 'top-end', icon: 'error', title: 'Error al obtener los usuarios', html: err, showConfirmButton: false, timer: 3500 })
            }).finally( () => {
                this.loading = false;
            })
        },
        editarUsuario( item ) {
            UsuarioDataService.jsonUsuario( item.id ).then( resp => {
                this.dataEditUsuario = resp.data;
                this.dataEditUsuario.roleName = resp.data.roleName;
                this.dataEditUsuario.empleadoId = resp.data?.empleado?.id;
                this.dialogEdit = true;
            }).catch( err => {
                this.$swal.fire({ position: 'top-end', title: 'Error', text: err.message, icon: 'error', showConfirmButton: false, timer: 1500 });
            });
        },
        lockUser( item ) {
            const message = item.empleado?.id 
                ? 'Al bloquear al usuario se desactivará su registro asociado (empleado).'
                : 'Al bloquear al usuario no se podrá revertir la acción.';

            this.$swal.fire({
                title: 'Estas seguro de bloquear el usuario?',
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, bloquear usuario!',
                cancelButtonText: 'Cancelar'
            }).then(result => {

                if (result.isConfirmed) {
                    UsuarioDataService.lockUser( item.id ).then( resp => {
                        this.initTableUser();
                        this.$swal.fire({ position: 'top-end', title: 'Bloqueado', text: 'Usuario bloqueado con exito', icon: 'success', showConfirmButton: false, timer: 1500 });
                    }).catch( err => {
                        this.$swal.fire({ position: 'top-end', title: 'Error', text: err.response, icon: 'error', showConfirmButton: false, timer: 1500 });
                    });
                }

            });
        },
        colorstatus ( status ) { return status ? 'primary' : 'pink'; },
        handleModal() { this.dialog = !this.dialog; },
        handleEditModal() { this.dialogEdit = !this.dialogEdit; },
        async getRoles() {
            this.roles = await UsuarioDataService.getAllRoles().then( resp => resp.data.roles ) || [];
        }
    },
    watch: {
        options: {
            handler( val, oldVal ) {
                if (val.page !== oldVal.page || val.itemsPerPage !== oldVal.itemsPerPage) {
                    this.initTableUser(val);
                }
            }, deep: true
        }
    },
    computed: {
        filteredHeaders() { return this.headers.filter( header => header.show ); }
    }
}
</script>
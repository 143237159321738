<template>
    <v-dialog v-model="dialog" persistent transition="scale-transition" max-width="1200px">
        <v-card clas="elevation-1">
            <ValidationObserver ref="refFormUser" v-slot="{invalid, validated}">
                <v-form name="form" @submit.prevent="save" lazy-validation>

                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click.native="handleModal">
                            <v-icon>fas fa-times-circle</v-icon>
                        </v-btn>

                        <v-toolbar-title>Registrar nuevo usuario</v-toolbar-title>
                        <v-spacer></v-spacer>

                        <v-toolbar-items>
                            <v-btn class="danger" text type="submit" :disabled="invalid || !validated">
                            <v-icon class="ma-2" dense large>fas fa-save</v-icon>Guardar usuario</v-btn>
                        </v-toolbar-items>
                    </v-toolbar>

                    <v-card-text>
                        <v-container fluid>

                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                        <v-autocomplete :items="roles" item-text="role" item-value="role" 
                                        v-model="usuario.roleName" label="Rol del usuario*" clearable prepend-icon="fas fa-user-tag"
                                        :error-messages="errors" :success="valid">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <v-autocomplete :items="empleados" item-text="nombreCompleto" item-value="id" 
                                    v-model="usuario.empleadoId" label="Empleado" clearable prepend-icon="fas fa-user">
                                    </v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                        <v-text-field label="usuario*" prepend-icon="fas fa-user-tag"
                                        required clearable v-model="usuario.username"
                                        :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                               </v-col>

                               <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required|email" v-slot="{ errors }">
                                        <v-text-field label="Correo Electronico*" prepend-icon="fas fa-envelope" type="email"
                                        required clearable v-model="usuario.email"
                                        :error-messages="errors">
                                        </v-text-field>
                                    </ValidationProvider>
                               </v-col>

                               <v-col cols="12" sm="6" md="4">
                                    <Password :password.sync="usuario.password" name="password" label="Contraseña*" :required="true"
                                    hint="Contraseña de acceso al sistema.." vid="password" rules="required|min:8|max:60" :immediate="false"/>
                                </v-col>

                               <v-col cols="12" sm="6" md="4">
                                    <Password :password.sync="usuario.passwordcp" name="passwordcp" label="Confirmar contraseña*" :required="true"
                                    hint="Digite la misma contraseña.." rules="required|min:8|max:60|confirmed:password" :immediate="false"/>
                               </v-col>
                            </v-row>
                            <small>* Indica son campos requeridos</small>

                        </v-container>
                    </v-card-text>

                </v-form>
            </ValidationObserver>
        </v-card>
    </v-dialog>
</template>

<script>
import Password from '@/components/shared/Password.vue';
import UsuarioDataService from '@/service/usuario/usuarioDataService';

export default {
    name: "NuevoUsuario",
    props: {
        dialog: { default: false },
        handleModal: { type: Function },
        initialize: { type: Function },
        roles: { type: Array }
    },
    components: { Password },
    created() {
        this.getEmpleados();
    },
    data() {
        return {
            empleados:       [],
            usuario: {
                id:         null,               
                username:   "",
                password:   "",
                passwordcp: "",
                email:      "",
                roleName:   null,
                empleadoId: null
            },
            searchDep:      "",
        }
    },
    methods: {
        save() {
            this.$refs.refFormUser.validate().then( isValid => {
                if( isValid ) {
                    UsuarioDataService.create( this.usuario ).then(_ => {
                        this.$refs.refFormUser.reset();
                        this.handleModal();
                        this.initialize();
                        this.$swal.fire({ position: 'top-end', title: 'Usuario creado', text: 'El usuario se ha creado correctamente', icon: 'success', showConfirmButton: false, timer: 2000 })
                    }).catch( err => {
                        this.$swal.fire({
                            position: 'top-end', title: 'Error, usuario no registrado', text: err.response,
                            icon: 'error', showConfirmButton: false, timer: 2000
                        });
                    });
                }
            })
        },
        async getEmpleados() {
            this.empleados = await UsuarioDataService.getAllEmpleado().then( resp => resp.data ) || [];
        }
    }
}
</script>